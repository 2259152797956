<template>
  <div>
    <div class="i-layout-page-header header-title">
      <span class="ivu-page-header-title">{{ $route.meta.title }}</span>
    </div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Button type="primary" @click="handleCreateAnnouncement">添加公告</Button>
      <Table
        class="l-m-t25"
        :columns="columns"
        :data="dataList"
        ref="table"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="status">
          <i-switch
            v-model="row.status"
            :value="row.status"
            :true-value="0"
            :false-value="1"
            size="large"
            @on-change="handleChangeStatus(row)">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </template>
        <template slot-scope="{ row }" slot="action">
          <a @click="handleEdit(row)">查看</a>
          <Divider type="vertical"/>
          <a @click="handleEdit(row)">编辑</a>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" :current="searchWhere.page" :page-size="searchWhere.limit" show-elevator show-total @on-change="handlePageChange"/>
      </div>
    </Card>
  </div>
</template>

<script>
import {
  getAnnouncementListApi
} from '@/api/marketing'

export default {
  name: 'index',
  data () {
    return {
      // 搜索条件
      searchWhere: {
        page: 1,
        limit: 15
      },
      // 表格数据
      dataList: [],
      columns: [
        { title: '公告名称', key: 'title' },
        { title: '公告简介', key: 'explain' },
        { title: '公告状态', slot: 'status' },
        { title: '操作', slot: 'action', width: 120 }
      ],
      loading: false,
      total: 0
    }
  },
  mounted () {
    this.getAnnouncementListRequest()
  },
  methods: {
    getAnnouncementListRequest () {
      this.loading = true
      getAnnouncementListApi(this.searchWhere).then(res => {
        const { count, list } = res.data
        this.loading = false
        this.total = count
        this.dataList = list
      }).catch(err => {
        this.loading = false
        this.$Message.error(err)
      })
    },
    // 公告添加
    handleCreateAnnouncement () {},
    // 处理编辑
    handleEdit () {},
    // 状态修改
    handleChangeStatus () {},
    // 分页处理
    handlePageChange () {}
  }
}
</script>

<style scoped>

</style>
