import request from '@/utils/request'

// =============================
// TODO: 付费会员管理国度
// =============================
/**
 * 会员类型
 */
export function getUsersMemberShipTypeApi () {
  return request({
    url: 'marketing/member/ship',
    method: 'get'
  })
}

/**
 * 会员权益
 * @returns {*}
 */
export function getUsersMemberRightApi () {
  return request({
    url: 'marketing/member/right',
    method: 'get'
  })
}

/**
 * 会员权益 -- 添加或编辑
 * @param data
 * @returns {*}
 */
export function memberRightSave (data) {
  return request({
    url: `marketing/member_right/save/${data.id}`,
    method: 'post',
    data
  })
}

/**
 * 会员卡记录
 * @param params
 * @returns {*}
 */
export function getUsersMemberRecordApi (params) {
  return request({
    url: 'marketing/member/record',
    method: 'get',
    params
  })
}

// =============================
// TODO: 公告管理国度
// =============================
export function getAnnouncementListApi (params) {
  return request({
    url: 'marketing/announcement',
    method: 'get',
    params
  })
}
